import React, { FC } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql, Link } from "gatsby"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import {
  BlogPostData,
  BlogPostPageContext,
  ContinueReadingSection,
  PreviousNextSection,
  readingTime,
  PreviousArrow,
} from "../blogUtils"
import { ContactSection } from "../commonComponents"

const BlogPost: FC<{
  data: { contentfulBlogPost: BlogPostData }
  pageContext: BlogPostPageContext
}> = ({ data, pageContext }) => {
  const { contentfulBlogPost: blog } = data
  const RICH_TEXT_RENDER_OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4 blog-text-content">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="mb-3 mt-5 blog-title-new-design">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="mb-3 mt-5">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="mb-3 mt-5">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="mb-3 mt-5">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="mb-3 mt-5">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="mb-3 mt-5">{children}</h6>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank">
            {children}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        try {
          const id = node.data.target.sys.id
          const reference = data.contentfulBlogPost.blogContent.references.find(
            ref => ref.contentful_id === id
          )
          const { url, contentType } = reference.file
          if (contentType.includes(`image/`)) {
            return (
              <img
                className="mt-4 mb-4"
                style={{ maxWidth: "100%", margin: "auto", display: "block" }}
                src={url}
                alt={reference.title}
              />
            )
          }
          throw new Error("unidentified content type")
        } catch (e) {
          console.log(e)
          return null
        }
      },
    },
  }

  return (
    <Layout
      title={blog.title}
      keywords={blog.tags.join(",")}
      description={blog.summary.summary}
      image={blog.heroImage.file.url}
      article
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
    </Helmet>
      <div className="blog-background-container">
        <img
          src={blog.heroImage.file.url}
          alt={blog.heroImage.title}
          style={{ width: "auto", height: "100%" }}
        />
        <Link className="all-blogs-return-button" to="/blog">
          <PreviousArrow /> &nbsp; All Blogs
        </Link>
      </div>

      <div className="blog-raw-container" style={{ marginTop: "2%" }}>
        <section className="blog-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-meta-info">
                  {blog.createdAt} | {readingTime(blog.blogContent.raw)} Mins
                  read
                </div>
                <h1 className="blog-title-new-design">{blog.title}</h1>
                {renderRichText(
                  { raw: blog.blogContent.raw, references: null },
                  RICH_TEXT_RENDER_OPTIONS
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <PreviousNextSection
        previous={pageContext.previous}
        next={pageContext.next}
      />
      <ContinueReadingSection
        continueReadingPosts={pageContext.continueReadingPosts}
      />
      <ContactSection />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      createdAt(formatString: "MMM DD, YYYY")
      heroImage {
        title
        file {
          url
        }
      }
      slug
      summary {
        summary
      }
      tags
      blogContent {
        raw
        references {
          contentful_id
          title
          file {
            url
            contentType
          }
        }
      }
    }
  }
`
